.contact-form-container {
    margin-top: auto;
    padding: 24px 6vw;
    background: url("../../assets/backgrounds/background_03.png") no-repeat fixed;
    background-position: center;

    .header {
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 48px;
    }

    .contact-form-top-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 24px;
    }

    .personnal-info-container {
        display: flex;
        flex-direction: column;
        align-items: start;
        flex-wrap: wrap;
        padding-right: 10px;

        label {
            padding-right: 15px;
            white-space: pre-wrap;
            text-align: start;
            margin: auto 0;
        }

        input {
            border-radius: 4px;
            font-family: inherit;
            width: 220px;
            text-indent: 10px;
            height: 2em;
        }
    }

    .prestations-container {
        width: 50%;
    }

    .form-field {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 12px;
        width: 100%;
    }

    textarea {
        border-radius: 4px;
        font-family: inherit;
        resize: none;
        height: 100px;
        width: 100%;
        padding: 10px;
    }

    .basic-multi-select {
        width: 100%;
        margin-top: 12px;
    }

    .submit-button button {
        height: 2em;
        width: 100px;
        border-radius: 4px;
        background-color: white;

        &:hover {
            cursor: pointer;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .contact-form-container {
        background: none;
        text-align: start;

        .header {
            margin-bottom: 24px;
        }

        .contact-form-top-container {
            flex-direction: column;
            margin-bottom: 48px;

            .personnal-info-container {
                margin-bottom: 24px;
            }

            .form-field {
                flex-direction: column;
                margin-bottom: 18px;
                width: 100%;

                input {
                    height: 38px;
                    width: 100%;
                    margin-top: 12px;
                }
            }
        }

        .prestations-container {
            width: 100%;
        }

        textarea {
            height: 50vh;
            width: 100%;
        }
    }
}