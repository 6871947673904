@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: "Open Sans", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "AltaRegular";
  src: local("AltaRegular"),
    url("./public/fonts/Alta_regular.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "AltaCaption";
  src: local("AltaCaption"),
    url("./public/fonts/Alta_caption.otf") format("truetype");
  font-weight: light;
}

@font-face {
  font-family: "TenorSans";
  src: local("TenorSans"),
    url("./public/fonts/TenorSans-Regular.ttf") format("truetype");
  font-weight: light;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --mainColor: #000000;
  --mainColorLight: #ffffff;
  --secondaryColor: #a74a29;
  --textColor: #eee;
}
