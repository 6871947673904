.inspirations-slider-container {
    /* background: url("../../assets/backgrounds/background_03.png") no-repeat
    fixed;
    background-position: initial;
    background-size: cover; */
}

.inspirations-slider-container h2 {
    margin-bottom: 48px;
}

.slick-slider span {
    display: block;
    width: 12vw;
    margin: auto;
}

.slick-slider {
    margin-top: 24px;
    width: 90%;
    margin: auto;
}

.slick-slider img {
    margin: auto;
    width: 12vw;
    height: 12vw;
    object-fit: cover;
}

@media only screen and (max-width: 1024px) {
    .slick-slider img {
        margin: auto;
        width: 70vw;
        height: 70vw;
        object-fit: cover;
    }

    .slick-slider span {
       width: 70vw;
    }
}