.a-propos-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  height: 100vh;
  overflow: auto;
}

.a-propos-container h1 {
  margin-top: 48px;
  margin-bottom: 96px;
  color: var(--mainColor);
  font-size: 30px;
}

.a-propos-container p:nth-of-type(1) {
  margin-bottom: 48px;
}

.a-propos-container p:nth-of-type(2) {
  color: var(--secondaryColor);
}

.inspirations-container {
  width: 100%;
  margin-top: auto;
  padding: 48px 0;
  background: url("../assets/backgrounds/background_03.png") no-repeat fixed;
  background-position: center;
}

.inspirations-container .inspiration-image {
  margin-bottom: 18px;
}

@media only screen and (max-width: 1024px) {
  .a-propos-container {
    position: inherit;
    overflow: auto;
    padding: 100px 10vw;
  }

  .a-propos-container h1 {
    margin-top: 0;
  }

  .inspirations-container {
    margin-top: 24px;
    background: none;
    padding: 0;
  }

  .a-propos-container p:nth-of-type(1) {
    margin-top: 48px;
  }

  .a-propos-container p:nth-of-type(2) {
    margin-top: 48px;
    color: var(--secondaryColor);
  }
}