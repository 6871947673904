.home-container {
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-container .logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}
/* logo maeva */
.home-container .logo-maeva-vinatier {
  width: 70vh;
  max-width: 800px;
  aspect-ratio: 574.7/574.32;
}

/* flèche blanche */
.home-container .white_arrow_down {
  width: 50px;
  margin-top: auto;
  margin-bottom: 50px;
}

/* alchimie du décor */
.home-container .alchimie {
  font-size: 16px;
  color: white;
  margin-bottom: 48px;
  transform: translateY(-28px);
}

.home-container .home-page-project-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* description projets */
.home-container .home-page-description-project {
  margin-bottom: 48px;
  font-size: 15px;
  color: var(--mainColor);
  width: 70%;
}

.image-project {
  position: relative;
  margin-bottom: 48px;
}

.image-project img {
  width: 40%;
  filter: blur(3px) brightness(0.4);
}

.image-project span {
  position: absolute;
  color: var(--mainColorLight);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}

@media only screen and (max-width: 1024px) {
  .home-container {
    padding: 100px 10vw 50px;
  }

  .home-container .logo-container {
    height: calc(100vh - 80px);
  }

  .home-container .logo-maeva-vinatier {
    width: 100%;
  }

  .image-project img {
    width: 80%;
    filter: blur(3px) brightness(0.4);
  }

  .home-container .home-page-description-project {
    width: 100%;
  }

  .home-container .alchimie {
    font-size: 14px;
  }
}
