@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border: 0 !important;
}

header {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 15%;
  padding: 49px 2rem;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: 110%;
  /* background-position: -10px -10px;
  background-size: cover; */
  color: var(--textColor);
}

.navbarBgOcre {
  background: url("../../assets/backgrounds/background_02.png") no-repeat fixed;
  background-position: initial;
  background-size: cover;
}

.navbarBgBeige {
  background: url("../../assets/backgrounds/background_03.png") no-repeat fixed;
  background-position: top;
}

nav {
  display: flex;
  flex-direction: column;
}

nav a {
  margin: 1rem 0;
  color: var(--textColor);
  text-transform: uppercase;
  text-decoration: none;
  font-family: "AltaCaption";
  font-size: 20px;
}

nav a:hover {
  color: var(--secondaryColor);
}

nav a.active {
  text-decoration: underline 1px;
  text-underline-offset: 2px;
}

header .hidden-on-desktop {
  display: none;
}

header .white-cross {
  width: 32px;
  height: 32px;
}

header .burger {
  width: 32px;
  height: 32px;
  position: absolute;
  right: 1rem;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  display: none;
  font-size: 1.8rem;
}

@media only screen and (min-width: 1024px) {

  .sticky {
    position: fixed;

  }
}

@media only screen and (max-width: 1024px) {
  header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100%;
    position: fixed;
    background-color: none;
    padding: 0 0;
    z-index: 1100;
  }

  header .logo-navbar {
    display: block;
    width: 3rem;
    height: 3rem;
    margin: 0;
    margin-left: 12px;
  }

  /* hide nav logo on desktop */
  header .hidden-on-desktop {
    display: block;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #d7c4b0;
    transition: 0.4s;
    transform: translateX(100vw);
  }

  header .responsive_nav {
    transform: none;
  }

  header .nav-btn {
    display: block;
  }

  .nav-close-btn {
    position: absolute;
    top: 1.5rem;
    right: 1rem;
  }

  nav a {
    font-size: 1.5rem;
  }

  nav a:hover {
    color: var(--mainColorLight);
  }

  .navbarBgOcre {
    background-size: auto;
  }

  .navbarBgBeige {
    background-size: cover;
    background-position: left;
  }
}