/* modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 85%);
  display: flex;
  align-items: center;
}

.overlay .projectModal {
  display: block;
  max-height: 80%;
  max-width: 80%;
  margin: 60px auto;
  z-index: 999;
}

.overlay .dismiss {
  position: absolute;
  width: 20px;
  top: 20px;
  right: 20px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
}

.overlay-arrows_left {
  width: 50px;
  height: 50px;
}

.overlay-arrows_left {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  left: 25px;
  z-index: 999;
  cursor: pointer;
}

.overlay-arrows_right {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right {
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 25px;
  width: 50px;
  height: 50px;
  top: 50%;
  z-index: 999;
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .overlay-arrows_left {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 35px;
  left: 3px;
  z-index: 999;
  cursor: pointer;
  }

  .overlay-arrows_right {
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: -13px;
  width: 50px;
  height: 35px;
  top: 50%;
  z-index: 999;
  cursor: pointer;
  }
}