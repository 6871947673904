.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow: auto;
}

@media only screen and (max-width: 1024px) {
  .contact-container {
    position: inherit;
    text-align: center;
    padding: 100px 7vw;
  }

  .contact-container h1 {
    margin-top: 0;
  }
}