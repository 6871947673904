.projects-container {
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 0 6vw;

}

.projects-container .description {
  margin: 48px 24px;
}

.grid-projects {
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 24px;
  gap: 10px;

}

.grid-projects .project-image {
  width: 350px;
}

.grid-projects .project-element {
  position: relative;
  cursor: pointer;
}

.grid-projects .project-description {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: 0.5s;
  font-family: "AltaCaption";
  color: white;
  font-size: 20px;
}

.grid-projects .project-element:hover .project-image {
  filter: blur(2px);
}

.grid-projects .project-element:hover .project-description {
  opacity: 1;
}

@media only screen and (max-width: 1024px) {
  .projects-container {
    padding: 100px 10vw;
  }

  .grid-projects .project-element {
    position: inherit;
  }

  .project-description {
    display: none;
  }

  .grid-projects .project-image {
    width: 100%;
  }

  .grid-projects .project-element:hover .project-image {
    filter: none;
  }

  .projects-container .description {
    margin: 0 0 24px;
  }
}