.artisans-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 0 6vw;
  height: 100vh;
  overflow: auto;
}

.grid-artisans {
  display: flex;
  flex-direction: row;
  margin-top: 48px;
}

.conteneur-artisan {
  width: 30vw;
  margin-right: 48px;
  text-align: center;
}

.artisans-container .description {
  margin-top: 96px;
}

.artisans-container .artisan-card-text {
  margin: 8px;
}

.cards-artisans {
  display: flex;
  flex-wrap: wrap;
  margin-top: 48px;
}

.img-artisan {
  object-fit: cover;
  width: 30vw;
}

@media only screen and (max-width: 1024px) {
  .artisans-container {
    overflow: auto;
    padding-top: 80px;
  }
  .grid-artisans {
    flex-direction: column;
    align-items: center;
  }

  .artisans-container .description {
    margin-top: 24px;
  }

  .conteneur-artisan {
    margin-right: 0;
    margin-bottom: 48px;
    width: 80vw;
  }

  .img-artisan {
    width: 80vw;
  }
}