.App {
  display: flex;
}

.container {
  width: 85%;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.containerBgBeige {
  background: url("./public/assets/backgrounds/background_03.png") no-repeat fixed;
  background-position: initial;
  background-size: cover;
}

.containerBgOcre {
  background: url("./public/assets/backgrounds/background_02.png") no-repeat fixed;
  background-position: initial;
  background-size: cover;
}

a {
  text-decoration: none;
  color: inherit;
}

body::-webkit-scrollbar {
  display: none;
}

.font-face-ar {
  font-family: "AltaRegular";
  font-weight: 500 !important;
}

.font-face-ac {
  font-family: "AltaCaption";
}

.font-face-cardo {
  font-family: "Cardo", serif;
}

.font-face-tenor {
  font-family: 'TenorSans';
  line-height: 1.4;
}

.text-align-center {
  text-align: center;
}

.font-size-14 {
  font-size: 14px;
}
.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-22 {
  font-size: 22px;
}

.mb12 {
  margin-bottom: 12px;
}

.mb24 {
  margin-bottom: 24px;
}

.mb48 {
  margin-bottom: 48px;
}

.mt12 {
  margin-top: 12px;
}
.mt24 {
  margin-top: 24px;
}
.mt48 {
  margin-top: 48px;
}

.w50 {
  width: 50%
}
.w100 {
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  .App {
    display: block;
    height: 100vh;
    max-height: -webkit-fill-available;
  }

  .container {
    /* padding top à modifier car la hauteur de la nav bar est différente */
    /* padding: 100px 10vw 0; */
    width: 100%;
  }

  .containerBgBeige {
    background: #e4d6c4;
  }

  /* .containerBgOcre {
    background: none;
  } */
  /* .containerBgBeige {
    background-position: -10px -10px;
    background-size: auto;
  } */
  .containerBgOcre {
    background-position: -10px -10px;
    background-size: auto;
  }
}